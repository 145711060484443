<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>留学问答</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>留学问答</span>
        </div>
         <el-tabs v-model="query.cateid" @tab-click="getList">
          <el-tab-pane
            v-for="item in questionType"
            :label="item.label"
            :key="item.id"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column label="所属分类">
            <template slot-scope="scope">{{
              scope.row.basicDataInfo.title
            }}</template>
          </el-table-column>
          <el-table-column prop="viewCount" label="浏览量"></el-table-column>
          <el-table-column prop="teacherID" label="回答老师">
            <template slot-scope="scope">{{
              scope.row.teacherInfo.showName
            }}</template>
          </el-table-column>
          <!-- <el-table-column prop="content" label="回答内容"></el-table-column> -->
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="isEnable" label="是否展示">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isEnable == true"
                >是</el-tag
              >
              <el-tag type="warn" v-else>否</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑留学问答' : '添加留学问答'"
      :visible.sync="dialogVisible"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item
          label="问题标题"
          prop="title"
          :rules="[
            { required: true, message: '请输入问题标题' },
            { min: 2, max: 30, message: '标题最少2字，最大30字' },
          ]"
        >
          <el-input
            v-model="form.title"
            placeholder="请输入问题标题"
          ></el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item
              label="回答老师"
              prop="teacherID"
              :rules="[{ required: true, message: '不能为空' }]"
            >
              <el-select
                v-model="form.teacherID"
                placeholder="请选择老师"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in teacherList"
                  :label="item.showName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="所属分类"
              prop="cateID"
              :rules="{ required: true, message: '不能为空' }"
            >
              <el-select
                v-model="form.cateID"
                placeholder="请选择分类"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in questionType"
                  :label="item.label"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="排序字段" prop="sort">
          <el-input
            v-model.number="form.sort"
            placeholder="请输入排序字段"
          ></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              label="是否展示"
              :rules="{ required: true, message: '不能为空' }"
            >
              <el-switch v-model="form.isEnable"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          label="回答内容"
          prop="content"
          :rules="{ required: true, message: '不能为空' }"
        >
          <vue-ueditor-wrap v-model="form.content" :config="editorConfig">
          </vue-ueditor-wrap>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      domain: url.getDomain(),
      key: "",
      showName: "",
      query: {cateid:'9'}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      teacherList: [],
      teacherType: [],
      questionType: [], //类型列表
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    //查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getQuestion("getlist_question");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    // 获取老师列表
    teachers: function () {
      var _this = this;
      var link = url.getTeacher("GetAllTeacher");
      $.get(link, {}, (data) => {
        _this.teacherList = data;
      });
    },
    //获取教师类型列表
    teacherTypeList: function () {
      var _this = this;
      var link = url.getTeacher("GetAllTeacherType");
      $.get(link, {}, (data) => {
        _this.teacherType = data;
      });
    },
    //获取问题类型列表
    question: function () {
      var _this = this;
      var modelid = 1;
      var link = url.getSys_BasicData("GetBaseTree");
      $.get(link, { modelid: modelid }, (data) => {
        _this.questionType = data;
      });
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getQuestion("save_question");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var link = url.getQuestion("delete_question"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    //获取问题列表
    this.question();
    // 获取教师类型列表
   // this.teacherTypeList();
    //  获取教师列表
    this.teachers();
    //获取服务器列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
